import { default as FluroContent, API } from "./fluro-content";
import Progress from "@/modules/meo-module/progress-document";

export default {
    install: function(Vue, { api }) {
        if (!api) throw new Error("API for fluro endpoint required");

        API.use(api);

        Vue.prototype.$meo = Vue.observable({
            ready: false,
            user: null,
            loading: false,
            error: {},
            bundle: "618da2d6714d4e063ae57c25",
            integration: "61e78d7a6395142ec9127165",
            progress: {},
            initialised: async () => {
                while (!Vue.prototype.$meo.ready) {
                    await new Promise((resolve) => setTimeout(resolve, 300));
                }

                return;
            },
            init: async (user) => {
                await Vue.prototype.$fluro.resetCache();

                Vue.prototype.$meo.loading = true;

                // Set defaults
                Vue.prototype.$meo.user = user;
                // let progress = await Vue.prototype.$fluro.api;
                // let progress = await Progress.list().then(({ data }) => data);
                // console.log(progress, "ProgressDoc in Meo-module Index");
                // if (!progress.length) {
                //   let localProgress = JSON.parse(window.localStorage.getItem("progress"));
                //   progress = Vue.prototype.$localProgress.progressDocInit(localProgress);
                // }

                Vue.prototype.$meo.ready = true;

                Vue.prototype.$meo.loading = false;
            },
            clearSession() {
                Vue.prototype.$meo.user = null;
                Vue.prototype.$meo.ready = false;
            },
        });
    },
};