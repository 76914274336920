<template>
<v-card class="meo-feather-image" :class="{ padding: padding }" @click="clicked()" :ripple="avatar ? true : false">
    <div :class="[!avatar ? 'image-wrapper' : 'avatar-wrapper']">
        <div class="image" :style="backgroundImage(src)"></div>
    </div>

    <div class="p-absolute bottom20 right5 pa-2 light border-radius-100 d-flex align-center justify-center elevation-5" v-if="avatar">
        <v-icon>mdi-camera-outline</v-icon>
    </div>
</v-card>
</template>

<script>
export default {
    name: "meo-feather-image",

    props: {
        src: [String, Object],
        padding: Boolean,
        avatar: Boolean,
    },

    methods: {
        backgroundImage(src) {
            return {
                backgroundImage: `url('${src}')`,
            };
        },
        clicked() {
            if (!this.avatar && this.$route.name !== "modules") {
                this.$router.push({ name: "modules" });
            } else {
                this.$emit("clicked");
            }
        },
    },
};
</script>

<style lang="scss">
.meo-feather-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 280px;
    height: 280px;
    background: var(--v-light-base);
    border-radius: 100% !important;
    box-shadow: 0 0 10px 10px var(--v-light-base) !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:focus {
        border-radius: 100% !important;
    }

    &.padding {
        padding: 15px;
    }

    .image {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;

        overflow: hidden;
        // transition: background-image 0.5s linear;
    }

    .image-wrapper {
        height: 100%;
        width: 100%;
        border-radius: 100%;
        padding: 12px !important;
    }

    .avatar-wrapper {
        height: 100%;
        width: 100%;
        border-radius: 100%;
        padding: 0 !important;
        overflow: hidden;
    }
}
</style>
