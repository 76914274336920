<template>
<div class="meo-footer">
    <v-container class="pa-0" style="max-width: 500px" >
        <v-tabs class="light" :class="{horizontal : !vertical, vertical : vertical}" :hide-slider="vertical" :vertical="vertical"  centered fixed-tabs center-active background-color="light">
            <v-tab :class="{'my-5' :$vuetify.breakpoint.mdAndUp, 'v-tab--active': $route.meta.key === config.buttons[index].title.toLowerCase()}" class="primary--text desktop-links" v-for="(button, index) in config.buttons" :key="index" :to="{ name: button.name }">
                <v-icon class="icon mb-1" color="dark" v-text="button.icon" :class="{'desktop-sidebar-icon' :$vuetify.breakpoint.mdAndUp}"></v-icon>
                <p class="dark--text" :class="{'text-h6' :$vuetify.breakpoint.mdAndUp}">{{ button.title }}</p>
            </v-tab>
        </v-tabs>
    </v-container>
</div>
</template>

<script>
export default {
    name: 'meo-footer',

    props: {
        config: {
            type: Object
        },
        vertical: {
            type: Boolean
        }
    }, 
}
</script>

<style lang="scss">
.meo-footer {

    @media(max-width: 960px){
        position:fixed;
        bottom: 0;
        width: 100vw;
        background-color: white;
        padding-bottom: env(safe-area-inset-bottom);
        z-index: 5;

    }


    .vertical {
        width: 100%;
        box-shadow: none !important;
        position: relative;
        flex-shrink: 0;
        // background: #ffffff;
        z-index: 100;

        .v-tab {
            border-radius: 50px;
            height: 50px !important;
            display: flex;
            align-items: center;
          
            min-width: 0;
            opacity: 0.5;

            &.v-tab--active {
                opacity: 1;
            }

            p {
                margin: 0;
                font-size: 10px;
                font-weight: bold;
                text-transform: none;
                white-space: nowrap;
            }

            .icon {
                height: 20px;
                margin-right: 8px;
            }
        }
    }

    .horizontal {

        
        width: 100%;
        box-shadow: none !important;
        position: relative;
        flex-shrink: 0;
        height: 57px !important;
        // background: #ffffff;
        z-index: 100;

        .v-tabs-bar {
            height: 100%;
            width: 100%;
            padding: 5px 0;

            .v-slide-group__next,
            .v-slide-group__prev {
                display: none !important;
            }

            .v-slide-group__wrapper {
                width: 100%;

                .v-slide-group__content {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .v-tabs-slider-wrapper {
                        bottom: 5px;

                        .v-tabs-slider {
                            width: 30px !important;
                            border-radius: 10px !important;
                            margin: auto !important;
                        }
                    }
                }
            }
        }

        .v-tab {
            border-radius: 50px;
            height: 100% !important;
            display: flex;
            align-items: center;
            // flex-direction: column;
            min-width: 0;
            opacity: 0.5;

            &.v-tab--active {
                opacity: 1;
            }

            p {
                margin: 0;
                font-size: 10px;
                font-weight: bold;
                text-transform: none;
                white-space: nowrap;
            }

            .icon {
                height: 20px;
                margin-right: 8px;
            }
        }
    }
}

.desktop-sidebar-icon{
    font-size: 30px !important;
}


//aligned nav options to start at same point, centered looks better so keeping commented out
// @media(min-width: 960px){
//     .desktop-links{
//         justify-content: flex-start !important;
//         margin-left:50px !important;
//         margin-right: 0px !important;
//         width: auto !important;
//     }

// }

</style>
