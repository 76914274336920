<template>
  <div class="meo-navigation">
    <v-navigation-drawer width="300" permanent>
      <div class="navigation-inner">
        <meo-ripple @clicked="clicked" :config="ripple"></meo-ripple>

        <input type="file" ref="file" @change="fileSelected($event.target.files)" class="d-none" accept="image/*" />

        <v-container class="mt-6 full-height">
          <v-row class="p-relative zindex-1">
            <v-col cols="12" align="center" justify="center">
              <h3 class="heading--text">Mastering Emotional Health</h3>
              <p class="muted-1 ma-0">21 ways to enhance your wellbeing</p>
            </v-col>

            <v-col cols="12">
              <meo-footer vertical :config="config"></meo-footer>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <!-- <v-col v-if="isLoggedIn" cols="12" class="d-flex justify-center pa-0 primary--text" >
                     
                    </v-col> -->

            <v-col cols="12">
              <div v-if="isLoggedIn" class="d-flex flex-column align-center">
                <p class="font-18">Welcome {{ $meo.user.firstName }}!</p>

                <div class="d-flex align-center my-6 cursor-pointer" @click="$fluro.auth.logout()">
                  <v-icon class="icon mr-1" color="dark">mdi-logout</v-icon>
                  <p class="font-18 mb-0 pr-2">Logout</p>
                </div>
              </div>

              <v-tabs
                v-else
                class="light logout"
                hide-slider
                centered
                fixed-tabs
                center-active
                background-color="light"
              >
                <v-tab class="primary--text" @click="goTo()">
                  <v-icon class="icon mb-1" color="dark">mdi-login</v-icon>
                  <p class="dark--text font-18 mb-1">Login</p>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
// Components
import MeoRipple from "@/components/misc/meo-ripple.vue";
import MeoFooter from "@/components/layout/meo-footer.vue";

import _ from "lodash";

export default {
  name: "meo-navigation",

  props: {
    config: {
      type: Object,
    },
    moduleImage: {
      type: Object,
    },
  },

  components: {
    MeoRipple,
    MeoFooter,
  },

  data() {
    return {
      date: new Date().getTime(),
      progress: 0,
      bytesLoaded: 0,
      bytesTotal: 0,
      session: {},
      // module: this.moduleImage,
      ripple: {
        image: require("@/assets/logo.png"),
        padding: true,
        avatar: false,
      },
    };
  },
  computed: {
    isLoggedIn() {
      if (this.$meo.user) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    async init() {
      this.$meo.loading = true;

      this.session = await this.$fluro.api.get("/session", { cache: false }).then(({ data }) => data);
      let avatar = await this.$fluro.asset.avatarUrl(this.session && this.session.contacts[0], "contact", {
        forceRefresh: true,
        date: this.date,
      });

      this.ripple.image = avatar;
      console.log(avatar, "config image");

      this.$meo.loading = false;
    },
    goTo() {
      this.$router.push({
        name: "auth.login",
      });
    },
    clicked() {
      if (!this.$meo.loading) this.$refs.file.click();
    },
    async fileSelected(files) {
      var self = this;
      self.$meo.loading = true;

      var file = _.first(files);

      console.log("file selected!", file);

      ///////////////////////////////////////

      var formData = new FormData();
      formData.append("file", file, "blob.jpg");

      ///////////////////////////////////////

      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          // do whatever you like with the percentage complete
          // maybe dispatch an action that will update a progress bar or something
          self.progress = percentCompleted;
          self.bytesLoaded = progressEvent.loaded;
          self.bytesTotal = progressEvent.total;

          ///////////////////////////////////////////////////

          self.$forceUpdate();
        },
      };

      ///////////////////////////////////////
      console.log("about to post Desktop", config, formData);
      return self.$fluro.api
        .post(`/contact/${self.session && self.session.contacts[0]}/image`, formData, config)
        .then(function({ data }) {
          self.date = new Date().getTime();
          self.init();

          return data;
        })
        .catch(function(err) {
          console.log(err, "err");
          self.reset();
        });
    },
    reset() {
      console.log("do I run? reset");
      this.$meo.loading = false;
      this.progress = 0;
      this.bytesLoaded = 0;
      this.bytesTotal = 0;
    },
  },

  watch: {
    "$route.meta.key": function(v) {
      if (v && v == "profile") {
        this.init();
        this.ripple.avatar = true;
      } else {
        this.ripple = {
          image: require("@/assets/logo.png"),
          padding: true,
          avatar: false,
        };
      }
    },
    moduleImage(New, Old) {
      // console.log("watcher", New);
      this.ripple.image = this.$fluro.asset.imageUrl(New.data.image);
      this.$fluro.forceUpdate;
    },
  },
};
</script>

<style lang="scss">
.meo-navigation {
  height: 100%;

  .v-navigation-drawer__border {
    display: none !important;
  }

  .navigation-inner {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logout {
      width: 100%;
      box-shadow: none !important;
      position: relative;
      flex-shrink: 0;
      // background: #ffffff;
      z-index: 100;
      flex-direction: column;

      .v-tab {
        border-radius: 50px;
        height: 50px !important;
        display: flex;
        align-items: center;
        // flex-direction: column;
        min-width: 0;
        opacity: 0.5;

        &.v-tab--active {
          opacity: 1;
        }

        p {
          margin: 0;
          font-size: 10px;
          font-weight: bold;
          text-transform: none;
          white-space: nowrap;
        }

        .icon {
          height: 20px;
          margin-right: 8px;
        }
      }
    }
  }
}

.font-18 {
  font-size: 18px !important;
  font-weight: bold !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
