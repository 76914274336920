let FluroAPI

export const API = {
    use: function(api) {
        FluroAPI = api
    }
}

class FluroContent {
    constructor(definition) {
        this.definition = definition
        this.uri = '/content/' + definition
    }

    list(options) {
        return FluroAPI.get(this.uri, options)
    }

    get(id, options) {
        return FluroAPI.get(`${this.uri}/${id}`, options)
    }

    create(payload, options) {
        return FluroAPI.post(this.uri, payload, options)
    }

    update(id, payload, options) {
        return FluroAPI.put(`${this.uri}/${id}`, payload, options)
    }

    delete(id, options) {
        return FluroAPI.delete(`${this.uri}/${id}`, options)
    }

    query(payload, options) {
        return FluroAPI.post('/content/_query', payload, options)
    }

    getQuery(options) {
        return FluroAPI.get(`/content/_query/${options}`)
    }
}

export default FluroContent