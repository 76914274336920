import FluroContent from "./fluro-content";

class Contact extends FluroContent {
	constructor() {
		super("contact");
	}

	list(options) {
		return super.list(options);
	}

	get(id, options) {
		return super.get(id, options);
	}

	create(payload, options) {
		return super.create(payload, options);
	}

	update(id, payload, options) {
		return super.update(id, payload, options);
	}

	delete(id, options) {
		return super.delete(id, options);
	}
}

const _Contact = new Contact();
export default _Contact;
