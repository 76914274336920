<template>
    <v-app id="app">
        <div class="safe-area-padding-top light zindex-10"></div>

        <v-img v-if="$vuetify.breakpoint.mdAndUp && isCreated" class="ma-4 mr-0 Logo-app" src="@/assets/glf-logo-full.png" contain></v-img>

        <div class="navigation-container" :class="{ desktop: $vuetify.breakpoint.mdAndUp }">
            <meo-navigation :moduleImage="moduleImage" :config="config" v-if="!hideFooter && $vuetify.breakpoint.mdAndUp"></meo-navigation>

            <v-main class="flex-grow-1 flex-shrink-1">
                <transition name="slide-y-transition" mode="out-in" appear>
                    <router-view @imageToNav="imageToNav" :scrolled="scrolled" />
                </transition>
            </v-main>
        </div>

        <meo-footer :config="config" v-if="!hideFooter && $vuetify.breakpoint.smAndDown"></meo-footer>

        <transition name="fade-transition" mode="out-in" appear>
            <v-overlay color="light" opacity="0.85" class="loading-overlay zindex-max" v-if="$meo.loading">
                <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </transition>

        <alert-dialog
            class="zindex-max"
            ref="updateDialog"
            @confirm="confirmUpdate"
            @cancel="cancelUpdate"
            title="App Update"
            action-label="Update"
        />

        <div v-if="$vuetify.breakpoint.mdAndUp" class="safe-area-padding-bottom light zindex-10"></div>
    </v-app>
</template>

<script>
const userAgent = navigator.userAgent || navigator.vendor || window.opera;
const parentProtocol = window.parent.location.protocol || "http";
const parentHost = window.parent.location.host || "localhost";

import AlertDialog from "@/components/dialogs/alert-dialog.vue";
import MeoFooter from "@/components/layout/meo-footer.vue";
import MeoNavigation from "@/components/layout/meo-navigation.vue";

import UserMixin from "@/mixins/user-mixin";

import _ from "lodash";

//plugins
let App;
try {
    App = window.parent.Capacitor.Plugins.App;
} catch (e) {
    console.error("(App) Capacitor not found.");
}

export default {
    name: "App",
    components: {
        AlertDialog,
        MeoFooter,
        MeoNavigation,
    },
    mixins: [UserMixin],
    meta: {
        title: "Mastering Emotional Health",
    },
    data() {
        return {
            moduleImage: {},
            updateChecked: 0,
            platform: "web",
            fullscreen: false,
            scrolled: false,
            isCreated: false,
            config: {
                buttons: [
                    {
                        title: "Sessions",
                        name: "modules",
                        icon: "mdi-book-open-blank-variant",
                    },
                    {
                        title: "Resources",
                        name: "resources",
                        icon: "mdi-archive-outline",
                    },
                    {
                        title: "Profile",
                        name: "profile.menu",
                        icon: "mdi-account-outline",
                    },
                ],
            },
        };
    },
    created() {
        console.log("VERSION 1.0.1");

        var self = this;
        this.isCreated = true;
        if (App) {
            this.platform = window.parent.APP_PLATFORM || "web";

            //Window fullscreen checker
            window.onresize = () => this.setFullscreen();

            setTimeout(function() {
                window.parent.postMessage("READY", `${parentProtocol}//${parentHost}`);
            }, 1500);

            window.addEventListener("message", (event) => {
                if (event.data === "UPDATED_MANIFEST") {
                    console.log("UPDATED_MANIFEST_ENGINE");
                } else if (event.data === "UPDATED_FULL") {
                    console.log("UPDATED_FULL_ENGINE");
                } else if (event.data === "ENGINE_HAS_UPDATE") {
                    console.log("ENGINE_HAS_UPDATE");
                    this.$refs.updateDialog.open(null, `There is a small app update. Would you like to apply this now?`);
                }
            });

            //first remove all app listeners
            if (window.parent.appListeners) {
                window.parent.appListeners.forEach((listener) => {
                    listener.remove();
                });
                window.parent.appListeners = [];
            } else {
                App.removeAllListeners();
            }

            window.parent.appListeners = window.parent.appListeners || [];

            const stateFunction = (state) => {
                // state.isActive contains the active state
                console.log("Internal App state changed.", state.isActive);
                if (state.isActive) this.checkUpdates();
            };

            if (window.parent.APP_ELECTRON_WINDOW) {
                const focusListener = {
                    fn: function() {
                        stateFunction({ isActive: true });
                    },
                    remove: function() {
                        window.parent.APP_ELECTRON_WINDOW.on("focus", this.fn);
                    },
                };
                window.parent.APP_ELECTRON_WINDOW.on("focus", focusListener.fn);
                window.parent.appListeners.push(focusListener);

                const blurListener = {
                    fn: function() {
                        stateFunction({ isActive: false });
                    },
                    remove: function() {
                        window.parent.APP_ELECTRON_WINDOW.on("blur", this.fn);
                    },
                };
                window.parent.APP_ELECTRON_WINDOW.on("blur", blurListener.fn);
                window.parent.appListeners.push(blurListener);
            } else {
                const appStateChange = App.addListener("appStateChange", stateFunction);
                window.parent.appListeners.push(appStateChange);
            }

            // Listen for serious plugin errors
            const pluginError = App.addListener("pluginError", (info) => {
                console.error("There was a serious error with a plugin INTERNAL", info);
            });
            window.parent.appListeners.push(pluginError);

            // async onBackButton() {
            // if (this.$route.name !== 'locked' && await Application.isLocked()) {
            // this.$router.push({ name: 'locked' });
            // } else if (this.$route.name !== 'locked' &&
            // this.$route.name !== 'set-pin') {
            // this.$router.go(-1);
            // }
            // }

            const backButton = App.addListener("backButton", (eventData) => {
                console.log("App back with URL: " + eventData.url);
                self.$router.go(-1);
                // App.exitApp()
            });

            window.parent.appListeners.push(backButton);

            const appUrlOpen = App.addListener("appUrlOpen", (eventData) => {
                // alert('Did launch application from the link!');

                console.log("App opened with URL: " + eventData.url);

                var url = new URL(eventData.url);

                if (url.pathname === "/auth/signup") {
                    self.$router.push({
                        name: "auth.signup",
                    });
                } else if (
                    (url.pathname === "/auth/reset" && url.searchParams.get("token")) ||
                    (url.pathname === "/auth/welcome" && url.searchParams.get("token"))
                ) {
                    self.$router.push({
                        name: "auth.reset",
                        query: { token: url.searchParams.get("token") },
                    });
                }
            });

            window.parent.appListeners.push(appUrlOpen);
        }
    },
    computed: {
        hideFooter() {
            return this.$route.meta.hideFooter;
        },
        authenticated() {
            //Returns true if we are running as an app or logged in as a user
            var isUser = this.user;
            var isApplication = this.application && this.application.authenticationStyle == "application";

            if (isUser || isApplication) {
                return true;
            }

            //We're not authenticated at all
            return false;
        },
        moduleImageValue() {
            console.log(this.moduleImage, "computed module image");
            return this.moduleImage;
        },
    },
    methods: {
        async checkUpdates() {
            const currentTime = new Date().getTime();
            const timeDiff = currentTime - this.updateChecked;

            if (timeDiff > 86400000) {
                window.parent.postMessage("CHECK_UPDATE", `${parentProtocol}//${parentHost}`);
            }

            await this.$meo.init(this.$meo.user);
        },
        confirmUpdate() {
            this.updateChecked = new Date().getTime();
            this.$refs.updateDialog.close();

            window.parent.postMessage("UPDATE_FULL", `${parentProtocol}//${parentHost}`);
        },
        cancelUpdate() {
            this.updateChecked = new Date().getTime();
        },
        setFullscreen() {
            setTimeout(() => {
                this.fullscreen = window.parent.APP_FULLSCREEN || false;
            }, 100);
        },
        handleScroll(e) {
            if (e.target.scrollTop >= 140) {
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
        imageToNav(moduleImage) {
            console.log("moduleIamge", moduleImage);
            this.moduleImage = moduleImage;
            console.log("this.moduleImage", this.moduleImage);
        },
    },
    mounted() {
        document.querySelector(".v-main__wrap").addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        document.querySelector(".v-main__wrap").removeEventListener("scroll", this.handleScroll);
    },
};
</script>
<style lang="scss">
*::-webkit-scrollbar {
    display: none;
}
* {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
}
body,
html {
    overscroll-behavior-x: none !important;
    overscroll-behavior-y: none !important;
    overscroll-behavior: none !important;

    * {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    @media (min-width: 960px) {
        width: 100vw;
        height: 100vh;
        position: relative;
    }

    @media (max-width: 960px) {
        // overflow: hidden;
        min-height: 100vh;
        #app {
            padding-bottom: calc(64px + env(safe-area-inset-bottom));
        }
    }

    .v-application {
        @media (min-width: 960px) {
            height: 100vh;
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        // * {
        //   font-family: "Roboto", serif !important;
        // }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 800;
        }

        i {
            font-size: 20px;
        }

        a {
            text-decoration: none;
            color: var(--v-secondary-base) !important;
        }

        .v-btn {
            font-weight: bold;
            text-transform: none;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            border-radius: 10px;

            &.v-btn--outlined,
            &.v-btn--text {
                box-shadow: none;
            }
        }

        .v-application--wrap {
            .navigation-container {
                height: 100%;
                overflow: hidden;

                .v-main {
                    height: 100%;
                    overflow: hidden;
                    background: var(--v-background-base) !important;

                    .v-main__wrap {
                        overflow-y: scroll;
                        overflow-x: hidden;
                        -webkit-overflow-scrolling: touch;
                    }
                }

                &.desktop {
                    display: flex;
                    align-items: flex-start;

                    .v-main {
                        background: var(--v-primary-base) !important;
                    }
                }
            }
        }
    }
}

.v-image,
.v-image__image,
.v-responsive__content,
.v-responsive__sizer {
    overflow: hidden !important;
}

.meo-gradient {
    background: rgb(255, 242, 0);
    background: radial-gradient(circle, rgba(255, 242, 0, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.module-section {
    background: #ffffff;
    border-radius: 10px !important;
    padding: 10px;
    position: relative;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
    display: flex;
    align-items: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 16px;
    }

    blockquote {
        padding: 15px;
        background: var(--v-primary-base);
        margin-bottom: 16px;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);

        p {
            font-weight: bold;

            em {
                font-weight: normal;
            }
        }
    }

    p {
        &:last-child {
            margin: 0;
        }
    }
}

.v-dialog__content {
    // z-index: 900 !important;

    .v-dialog {
        background: #ffffff;

        &.v-dialog--fullscreen {
            padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 20px)
                env(safe-area-inset-left, 20px);
            background: var(--v-background-base);
        }

        &.v-bottom-sheet {
            border-top-right-radius: 15px !important;
            border-top-left-radius: 15px !important;
            overflow: hidden !important;
            // height: 100%;
            padding-bottom: constant(safe-area-inset-bottom, 20px);
            padding-bottom: env(safe-area-inset-bottom, 20px);
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                // display: flex;
                position: relative;
                width: calc(100% - 20px);
                margin-left: auto;

                &:before {
                    content: "";
                    background: var(--v-primary-base);
                    font-weight: bold;
                    // display: inline-block;
                    display: block;
                    width: 10px;
                    height: 10px;
                    // flex-shrink: 0;
                    // margin-right: 10px;
                    // margin-top: 7px;
                    position: absolute;
                    top: 7px;
                    left: -20px;
                }
            }
        }
    }
}

.muted {
    opacity: 0.7;
}

.muted-1 {
    opacity: 0.5;
}

.muted-2 {
    opacity: 0.3;
}

.v-bottom-navigation {
    box-sizing: initial !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
}

.overflow-auto {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.full-height {
    height: 100%;
}
.min-height-100 {
    min-height: 100%;
}
.full-width {
    width: 100%;
}

.p-absolute {
    position: absolute !important;

    &.center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.top5 {
        top: 5px;
    }

    &.top10 {
        top: 10px;
    }

    &.top15 {
        top: 15px;
    }

    &.top20 {
        top: 20px;
    }

    &.top25 {
        top: 25px;
    }

    &.bottom5 {
        bottom: 5px;
    }

    &.bottom10 {
        bottom: 10px;
    }

    &.bottom15 {
        bottom: 15px;
    }

    &.bottom20 {
        bottom: 20px;
    }

    &.left5 {
        left: 5px;
    }

    &.left10 {
        left: 10px;
    }

    &.left15 {
        left: 15px;
    }

    &.left20 {
        left: 20px;
    }

    &.right5 {
        right: 5px;
    }

    &.right10 {
        right: 10px;
    }

    &.right15 {
        right: 15px;
    }

    &.right20 {
        right: 20px;
    }
}
.p-relative {
    position: relative !important;
}
.p-fixed {
    position: fixed !important;

    &.top5 {
        top: 5px;
    }

    &.top10 {
        top: 10px;
    }

    &.top15 {
        top: 15px;
    }

    &.top20 {
        top: 20px;
    }

    &.bottom5 {
        bottom: 5px;
    }

    &.bottom10 {
        bottom: 10px;
    }

    &.bottom15 {
        bottom: 15px;
    }

    &.bottom20 {
        bottom: 20px;
    }

    &.left5 {
        left: 5px;
    }

    &.left10 {
        left: 10px;
    }

    &.left15 {
        left: 15px;
    }

    &.left20 {
        left: 20px;
    }

    &.right5 {
        right: 5px;
    }

    &.right10 {
        right: 10px;
    }

    &.right15 {
        right: 15px;
    }

    &.right20 {
        right: 20px;
    }
}

.no-selection {
    -webkit-user-select: none;
    user-select: none;
}

.zindex-1 {
    z-index: 1;
}
.zindex-2 {
    z-index: 2;
}
.zindex-3 {
    z-index: 3;
}
.zindex-4 {
    z-index: 4;
}
.zindex-5 {
    z-index: 5;
}
.zindex-6 {
    z-index: 6;
}
.zindex-7 {
    z-index: 7;
}
.zindex-8 {
    z-index: 8;
}
.zindex-9 {
    z-index: 9;
}
.zindex-10 {
    z-index: 10;
}
.zindex-max {
    z-index: 9999 !important;
}

.opacity-50 {
    opacity: 50% !important;
}
.opacity-60 {
    opacity: 60% !important;
}
.opacity-70 {
    opacity: 70% !important;
}
.opacity-80 {
    opacity: 70% !important;
}
.opacity-90 {
    opacity: 70% !important;
}

.overflow-ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.border-radius-100 {
    border-radius: 100%;
}

.safe-area-padding-top {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
    background: rgba(#5f54e0, 0.3);
}

.safe-area-margin-top {
    margin-top: constant(safe-area-inset-top) !important;
    margin-top: env(safe-area-inset-top) !important;
    // background: rgba(#5F54E0, 0.3);
}

.safe-area-padding-bottom {
    padding-bottom: constant(safe-area-inset-bottom) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
}

th.valign-center {
    span {
        vertical-align: middle;
    }
}

div.v-text-field--outlined fieldset {
    border-color: var(--v-primary-light-base);
    background: #ffffff !important;
}

.v-input {
    .v-input__control {
        .v-input__slot {
            .v-input__prepend-inner {
                z-index: 1;
            }
        }
    }
}

.v-btn:hover:before,
.v-tab:hover:before {
    background-color: transparent;
}
.desktopAlign {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100%;
}

.visibility-hidden {
    visibility: hidden;
}

.margin-auto {
    margin: auto;
}

.Logo-app {
    max-height: 40px;
    max-width: 300px;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 64px;
}

.container {
    &.text-wrap {
        max-width: 900px;
        margin: auto;
    }
}
// .Logo-mobile-app{
//     display: none;
// }
</style>
