<template>
<div class="meo-ripple">
    <div class="ripple-1"></div>
    <div class="ripple-2"></div>
    <div class="ripple-3"></div>
    <div class="ripple-4"></div>
    <div class="ripple-5"></div>

    <meo-feather-image :src="config.image" :padding="config.padding" :avatar="config.avatar" @clicked="clicked"></meo-feather-image>
</div>
</template>

<script>
import MeoFeatherImage from "@/components/misc/meo-feather-image.vue";

export default {
    name: "meo-ripple",

    props: {
        config: {
            type: Object,
        },
    },

    components: {
        MeoFeatherImage,
    },

    methods: {
        clicked() {
            this.$emit("clicked");
        },
    },
};
</script>

<style lang="scss">
.meo-ripple {
    width: 100%;
    padding-top: 90%;
    position: relative;
    z-index: 0;
    max-width: 500px;
    max-height: 500px;
    margin: auto;

    .ripple-1,
    .ripple-2,
    .ripple-3,
    .ripple-4,
    .ripple-5 {
        opacity: 0;
        height: 130vw;
        width: 130vw;
        margin: -65vw;
        position: absolute;
        left: 50%;
        bottom: 50%;
        background-color: var(--v-primary-base);
        // transform: translate(-50%, -50%);
        animation-name: ripple;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
        will-change: transform, opacity;
        // clip-path: circle(50%);
        border-radius: 100%;
    }

    .ripple-1 {
        animation-delay: 0;
    }

    .ripple-2 {
        animation-delay: 1s;
    }

    .ripple-3 {
        animation-delay: 2s;
    }

    .ripple-4 {
        animation-delay: 3s;
    }

    .ripple-5 {
        animation-delay: 4s;
    }

    @keyframes ripple {
        0% {
            transform: scale(0);
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 0;
        }
    }

    .scale-enter-active,
    .scale-leave-active {
        transition: transform 1s;
        transform: translate(-50%, -50%) scale(1);
    }

    .scale-enter,
    .scale-leave-to {
        transform: translate(-50%, -50%) scale(0);
    }

    @media (min-width: 960px) {
        padding-top: 120%;

        .ripple-1,
        .ripple-2,
        .ripple-3,
        .ripple-4,
        .ripple-5 {
            height: 40vw;
            width: 40vw;
            margin: -20vw;
        }
    }

    @media (orientation: landscape) and (max-width: 960px) {
        padding-top: 50%;
    }

    @media (orientation: portrait) and (min-width: 768px) and (max-width: 1000px) {
        padding-top: 50%;
    }

    @media (orientation: portrait) and (min-width: 1024px) {
        padding-top: 150%;
    }
}
</style>
