import Vue from "vue";
import Router from "vue-router";
import store from "../store";

import VueMeta from "vue-meta";

///////////////////////////////////

//Routes

// Sessions
const Welcome = () => import("./routes/welcome");

// Sessions
const Modules = () => import("./routes/modules");
const ModuleList = () => import("./routes/modules");
const Module = () => import("./routes/modules/module");
const ModuleMenu = () => import("./routes/modules/module/menu.vue");
const ModuleScreen = () => import("./routes/modules/module/screen.vue");
const ModuleNotes = () => import("./routes/modules/module/notes.vue");
const ModuleCompleted = () => import("./routes/modules/module/completed.vue");

// Resources
const ResourceList = () => import("./routes/resources");
const Resource = () => import("./routes/resources/resource");

// Purchase
const Purchase = () => import("./routes/purchase");
// const PurchaseIndividual = () => import('./routes/purchase/individual.vue ');
// const PurchaseBundle = () => import('./routes/purchase/bundle.vue');

// Profile
const Profile = () => import("./routes/profile");
const ProfileMenu = () => import("./routes/profile/menu.vue");
const Favourites = () => import("./routes/profile/favourites.vue");
const Progress = () => import("./routes/profile/progress.vue");
const Details = () => import("./routes/profile/details.vue");
const Security = () => import("./routes/profile/security.vue");
const Article = () => import("./routes/profile/article.vue");

///////////////////////////////////

//User Authentication Routes
const User = () => import("./routes/user/user.vue");
const Login = () => import("./routes/user/login.vue");
const Signup = () => import("./routes/user/signup.vue");
const Forgot = () => import("./routes/user/forgot.vue");
const Reset = () => import("./routes/user/reset.vue");

///////////////////////////////////

//Use the router
Vue.use(Router);

///////////////////////////////////

var array = [];

///////////////////////////////////

array.push({
    name: "welcome",
    path: "/welcome",
    meta: {
        title: "Welcome",
    },
    component: Welcome,
});

//////////////////////////////////////

array.push({
    name: "auth",
    path: "/auth",
    meta: {
        title: "Start",
        denyUser: true,
    },
    component: User,
    children: [
        {
            name: "auth.login",
            path: "login",
            meta: {
                title: "Login",
                denyUser: true,
                hideFooter: true,
            },
            component: Login,
        },
        {
            name: "auth.signup",
            path: "signup",
            meta: {
                title: "Sign Up",
                denyUser: true,
                hideFooter: true,
                description: "Sign up for free to access free content and sessions.",
            },
            component: Signup,
        },
        {
            name: "auth.forgot",
            path: "forgot",
            meta: {
                title: "Forgot Password",
                denyUser: true,
                hideFooter: true,
            },
            component: Forgot,
        },
        {
            name: "auth.reset",
            path: "reset",
            meta: {
                title: "Reset Your Password",
                denyUser: true,
                hideFooter: true,
            },
            component: Reset,
            props: (route) => ({
                token: route.query.token,
            }),
        },
    ],
});

///////////////////////////////////

array.push({
    name: "modules",
    path: "/sessions",
    meta: {
        title: "Session",
        description: "Mastering Emotional Health sessions.",
    },
    component: ModuleList,
});

array.push({
    name: "module",
    path: "/sessions/:id",
    meta: {
        title: "Session",
        key: "sessions",
        description: "Mastering Emotional Health sessions.",
    },
    component: Module,
    children: [
        {
            name: "module.menu",
            path: "menu",
            meta: {
                title: "Menu",
                key: "sessions",
            },
            component: ModuleMenu,
        },
        {
            name: "module.completed",
            path: "completed",
            meta: {
                title: "Completed",
                key: "sessions",
            },
            component: ModuleCompleted,
        },
        {
            name: "module.notes",
            path: "notes",
            meta: {
                title: "Notes",
                key: "sessions",
            },
            component: ModuleNotes,
        },
        {
            name: "module.screen",
            path: "screen/:screenId",
            meta: {
                title: "Screen",
                key: "sessions",
            },
            component: ModuleScreen,
        },
    ],
});

///////////////////////////////////

array.push({
    name: "resources",
    path: "/resources",
    meta: {
        title: "Resources",
        description: "View additional resources from our content creators.",
    },
    component: ResourceList,
});

array.push({
    name: "resource",
    path: "/resources/:id",
    meta: {
        title: "Resource",
        description: "View additional resources from our content creators.",
    },
    component: Resource,
});

///////////////////////////////////

array.push({
    name: "purchase",
    path: "/purchase", //:id
    meta: {
        title: "Purchase",
        hideFooter: true,
        description:
            "We hope you're enjoying: Mastering Emotional Health — 21 ways to enhance your wellbeing. Purchase the bundle to unlock the full content.",
    },
    component: Purchase,
});

// array.push({
//     name: 'purchaseBundle',
//     path: '/bundle',
//     meta: {
//         title: 'Purchase Bundle',
//         hideFooter: true
//     },
//     component: PurchaseBundle,
// });
//
// array.push({
//     name: 'purchaseIndividual',
//     path: '/individual/:id',
//     meta: {
//         title: 'Purchase Individual',
//         hideFooter: true
//     },
//     component: PurchaseIndividual,
// });

///////////////////////////////////

array.push({
    name: "profile",
    path: "/profile",
    meta: {
        title: "Profile",
        requireUser: true,
        key: "profile",
        description: "View your session progress, manage your favorite content, and personalize your profile settings.",
    },
    component: Profile,
    children: [
        {
            name: "profile.menu",
            path: "menu",
            meta: {
                title: "Profile",
                requireUser: true,
                key: "profile",
            },
            component: ProfileMenu,
        },
        {
            name: "profile.favourites",
            path: "favourites",
            meta: {
                title: "My Favourites",
                requireUser: true,
                key: "profile",
            },
            component: Favourites,
        },
        {
            name: "profile.progress",
            path: "progress",
            meta: {
                title: "My Progress",
                requireUser: true,
                key: "profile",
            },
            component: Progress,
        },
        {
            name: "profile.details",
            path: "details",
            meta: {
                title: "My Details",
                requireUser: true,
                key: "profile",
            },
            component: Details,
        },
        {
            name: "profile.security",
            path: "security",
            meta: {
                title: "Security",
                requireUser: true,
                key: "profile",
            },
            component: Security,
        },
        {
            name: "profile.article",
            path: "article/:id",
            meta: {
                title: "Article",
                requireUser: true,
                key: "profile",
            },
            component: Article,
        },
    ],
});

///////////////////////////////////

var router = new Router({
    mode: "history",
    routes: array,
    scrollBehavior(to, from, savedPosition) {
        //Keep track of where the user was scrolled
        //if they hit the back button
        var pos = 0;
        // Scroll to the top
        if (savedPosition) {
            pos = savedPosition.y;
        }

        setTimeout(function() {
            document.querySelector(".v-main__wrap").scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }, 300);

        // document.body.scrollTop = document.documentElement.scrollTop = pos;
    },
});

///////////////////////////////////

router.beforeEach(async (to, from, next) => {
    if (!to || !to.meta || !to.name) {
        console.log("NO ROUTE");
        return next("/welcome");
    }

    if (to.meta) {
        //Get the user session from fluro
        let user = store.getters["fluro/user"];

        //If the route only allows logged in users
        if (to.meta.requireUser && !user) return next("/auth/login");

        //If the route doesn't allow logged in users
        if (to.meta.denyUser && user) return next("/welcome");
    }

    return next();
});

///////////////////////////////////

export default router;

///////////////////////////////////
