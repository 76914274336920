import Vue from "vue";
import MeoProgressDocument from "@/modules/meo-module/progress-document";
import Contact from "@/modules/meo-module/contact";

export default () => {
    Vue.prototype.$localProgress = Vue.observable({

        progressDoc: {},

        async progressDocInit() {
            let self = Vue.prototype.$localProgress;

            let progressDocument = await MeoProgressDocument.list().then(({ data }) => data);

            if (progressDocument && progressDocument.length) {
                //if one already exists then assign it to a data variable
                window.localStorage.removeItem("progress");
                return progressDocument[0];

            } else {
              
                let localStorageProgress = JSON.parse(window.localStorage.getItem("progress")) || [];

                console.log(Vue.prototype.$meo.user, "user info in Doc Init");

                    // if no progressDocument for this user create one with payload containing a section for contactID, notes, screens
                    let payload = {
                        title: `${Vue.prototype.$meo.user.firstName} ${Vue.prototype.$meo.user.lastName}`,
                        realms: ["618d91a9714d4e063ae578e9"],
                        data: {
                            modules: localStorageProgress,
                        },
                    };
                    progressDocument = await MeoProgressDocument.create(payload).then(({ data }) => data);
                    console.log("CREATED ProgressDoc", progressDocument);
                    self.progressDoc = progressDocument;

                    return progressDocument;
                
            }
        },

        async getLocalFavourites(contact){
           
        
            if(contact){
             
                let contactFavourites = contact?.data?.favourites || [];
                let localFavourites = JSON.parse(window.localStorage.getItem("favourites"));
    
                if(localFavourites && !contactFavourites?.length){
                    contact.data = {favourites: localFavourites};
                    let updatedContact = await Contact.update(contact._id, contact).then(({data}) => data);
                    
                    window.localStorage.removeItem("favourites");
                    
                } else if(localFavourites && contactFavourites?.length){
                    
                    window.localStorage.removeItem("favourites");
                }
            } else {
                
                return;
            }
            
        },

        async storeLocalProgressToCreatedProgressDoc(progressDocument, module) {

            let localProgress = JSON.parse(window.localStorage.getItem("progress"));

            if (localProgress) {
                let currentIndex = progressDocument.data.modules.findIndex((item) => item.module._id === module._id);
                let index2 = localProgress.findIndex((item) => item.module._id === module._id);

                if (index2 === -1) {
                    localProgress.forEach(item => progressDocument.data.modules.push(item))
                } else {
                    progressDocument.data.modules[currentIndex].screens = localProgress[index2].screens;
                    progressDocument.data.modules[currentIndex].notes = localProgress[index2].notes;
                    localProgress.filter(item => item.module._id !== module._id).forEach(item => progressDocument.data.modules.push(item));
                }
                await MeoProgressDocument.update(progressDocument._id, progressDocument).then(({ data }) => data);
                window.localStorage.removeItem("progress");
            }
        },

        localOnComplete(module, section) {
            //grab current storage items in local
            let currentStorageItems = JSON.parse(window.localStorage.getItem("progress"));

            // create a payload that can overwrite the progressDocument.data.modules
            let payload = {
                module: module,
                notes: "",
                screens: [section],
            };

            if (!currentStorageItems) {
                // if no current local storage for progress just put in the object with this section in the screens
                window.localStorage.setItem("progress", JSON.stringify([payload]));
            } else {
                // if multiple modules stored in local storage find the index of the current module
                let currentIndex = currentStorageItems.findIndex((item) => item.module._id === module._id);

                //if current module is NOT is localStorage, add this module's progress(payload) to localStorage
                if (currentIndex === -1) {
                    window.localStorage.setItem("progress", JSON.stringify([...currentStorageItems, payload]))
                } else {
                    // else, the Module is already in localStorage
                    // check if screen is NOT in currentStorageItems[].screens, if not, add the current section
                    if (!currentStorageItems[currentIndex].screens.find(screen => screen._id == section._id)) {
                        // filter out the current module and store a new (payload)module object (with updated screens)
                        let filteredStorage = currentStorageItems.filter((item, index) => index !== currentIndex)
                        payload.screens = [section, ...currentStorageItems[currentIndex].screens];
                        window.localStorage.setItem("progress", JSON.stringify([...filteredStorage, payload]));
                    }
                }
            }
        },

       

    });
};