import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        themes: {
            light: {
                primary: "#FFF200",
                secondary: "#C6451B",
                heading: "#6D6E71",
                grey: "#EEEEEE",
                'dark-grey': "#CCCCCC",
                dark: "#333333",
                background: "#F9F9F9",
                light: "#FFFFFF",
                success: "#46c93a",
                warning: "#ffba00",
                error: "#ff4757",
            },
            dark: {
                primary: "#FFF200",
                secondary: "#C6451B",
                heading: "#FFFFFF",
                grey: "#444444",
                dark: "#FFFFFF",
                background: "#121212",
                light: "#363636",
                success: "#46c93a",
                warning: "#ffba00",
                error: "#ff4757",
            },
        },
    },
});