<template>
    <v-dialog v-model="display" content-class="confirm" width="500">
        <v-card class="alert-dialog no-selection dark--text">
            <div class="pa-3">
                <b class="text-center" v-text="title"></b>
            </div>

            <v-card-text class="pa-3 mb-0 text-center" v-html="dialogMessage"></v-card-text>

            <v-card-actions class="actions pa-3">
                <v-btn v-if="!this.showError && !this.hideCancel" :disabled="!isValid" :color="cancelColor" uppercase @click="cancel"
                    ><span class="dark--text">{{ cancelLabel }}</span></v-btn
                >
                <v-btn v-if="!this.showError && !this.hideAction" :disabled="!isValid" :color="actionColor" uppercase @click="confirm"
                    ><span class="dark--text">{{ actionLabel }}</span></v-btn
                >
                <v-btn v-if="this.showError" :color="cancelColor" bold uppercase @click="close"><span class="dark--text">Close</span></v-btn>
            </v-card-actions>

            <v-overlay color="white" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
    props: {
        title: {
            type: String,
            default() {
                return "Alert";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "#EEEEEE";
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },
    data() {
        return {
            display: false,
            loading: false,
            showError: false,

            model: null,

            message: "",
            errorMessage: "",
        };
    },
    computed: {
        isValid() {
            return !this.loading;
        },
        dialogMessage() {
            if (this.showError) return this.errorMessage || "An error occured. Try again.";
            return this.message || "";
        },
    },
    methods: {
        init(model) {
            this.showError = false;
            this.loading = false;

            if (!model) {
                this.model = null;
            } else {
                let clonedModel = _.cloneDeep(model);
                this.model = clonedModel;
            }
        },
        open(model, message) {
            this.init(model);
            this.message = message;
            this.display = true;
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        confirm() {
            this.loading = true;
            this.$emit("confirm", this.model);
        },
        cancel() {
            this.$emit("cancel");
            this.display = false;
        },
        close() {
            this.$emit("close");
            this.display = false;
        },
    },
};
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 100px 80px rgba(74, 34, 202, 0.07), 0 22.3363px 17.869px rgba(74, 34, 202, 0.0417275), 0 6.6501px 5.32008px rgba(74, 34, 202, 0.0282725) !important;
}

.alert-dialog {
    border-radius: 10px !important;

    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }

    .p-absolute {
        position: absolute !important;
    }
    .p-relative {
        position: relative !important;
    }
}
</style>
