import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";

import "neuicons";

///////////////////////////////////////

import FluroVue from "fluro-vue";


Vue.use(FluroVue, { store });

/////////////////////////////////////////////////////


import Meta from 'vue-meta'

Vue.use(Meta, {
    keyName: 'meta',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
});

/////////////////////////////////////////////////////

//Listen for when the user session changes
var fluro = Vue.prototype.$fluro;
fluro.auth.addEventListener("change", userUpdated);

/////////////////////////////////////////////////////

//Keep track of whether the user is logged in
var previousLoggedInUser;
var vm;

// meo Module
import MEOModule from "@/modules/meo-module";
Vue.use(MEOModule, { api: fluro.api });

// localProgress prototype
import localProgress from "@/modules/local-progress.js";
Vue.use(localProgress);

//Everytime the user changes
async function userUpdated(user) {
    //Check if the user was logged in before this change
    var wasLoggedInAs = previousLoggedInUser;

    console.log("USER", user);

    if (user) {
        //We are authenticated as a user
        previousLoggedInUser = user;
        Vue.prototype.$meo.init(user);
        Vue.prototype.$localProgress.progressDocInit();


    } else {
        Vue.prototype.$meo.clearSession();
        console.log("Clearing Local Storage in Main.js")
        window.localStorage.removeItem("progress");
        // console.log("NO USER");
        //We are not logged in
        previousLoggedInUser = null;

        if (fluro.applicationToken) {
            //We are authenticated as a static application
        } else {
            //We are not authenticated at all
        }

        //If we were logged in before
        //and now we aren't
        if (wasLoggedInAs) {
            // vm.$toasted.show(`See you next time ${wasLoggedInAs.firstName}!`, {
            //     // icon:'check'
            //     duration: 3500,
            //     type: 'success',
            // })

            //Redirect to the home page
            console.log("Go to login");
            // router.push({ name: 'auth.login' });
        }

        router.push({ name: "auth.login" });
    }
}

///////////////////////////////////////

let user = store.getters["fluro/user"];
if (user) {
    Vue.prototype.$meo.init(user);
}

/////////////////////////////////////////////////////

//Listen for general Fluro errors and print them to screen
//then you can thow an error from anywhere like this this.$fluro.error(err)
fluro.addEventListener("error", function(message) {
    console.log("Error", message);
});

/////////////////////////////////////////////////////

//Use Fluro as an event bus for notifications
//this.$fluro.notication('some message', options) it means we can swap
//out the component we use to render the notifications easily
fluro.addEventListener("notification", function(details) {
    console.log("Notification event", details);
});

///////////////////////////////////////

//Include filters globally
Vue.prototype.$filters = Vue.options.filters;

Vue.directive("blur", {
    inserted: function(el) {
        el.onfocus = (ev) => ev.target.blur();
    },
});

Vue.filter("capitalize", function(value) {
    var string = value;
    return string.charAt(0).toUpperCase() + string.slice(1);
});
///////////////////////////////////////

// EVENT BUS
export const EventBus = new Vue();

// import '@/styles/styles.scss'

Vue.config.productionTip = false;

vm = new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");